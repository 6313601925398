import React from "react";
import "./ComponentStyles/footer.scss";

class Footer extends React.Component {
       
    render () { 
        return (
            <div className="bottomLine">
                <span><a className="bottomLink" href="/imprint">Imprint</a></span>
                <span><a className="hiddenLink" href="/changelog">Release 1.5</a>; Welcome!! ^w^</span>
                <span><a className="bottomLink" href="/privacy">Privacy Policy</a></span>
            </div>
        );
    }
}



export default Footer;
