import './App.css';
import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import HomePage from './pages/home.jsx';
import Sonas from './pages/sonas.jsx';
import MePage from './pages/me.jsx';
import Boop from './pages/boop.jsx';
//import TestPage from './pages/test.jsx';
import Commissions from './pages/commissions.jsx';
import Navbar from './pages/Components/navbar.jsx';
import Footer from './pages/Components/footer.jsx';
import Imprint from './pages/imprint';
import Privacy from './pages/privacy.jsx';
import ToS from './pages/tos.jsx';
import ChangeLog from './pages/changelog.jsx';
import NotFound from './pages/notFound';
import SocialMedia from './pages/social_media';
import OtherProjects from './pages/other_projects';


class App extends Component {
  render() {
    return (
      <div className="App">
        <Navbar />
        <div className="MainPage">
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/sonas" element={<Sonas />} />
            <Route path="/me" element={<MePage />} />
            <Route path="/other_projects" element={<OtherProjects />} />
            <Route path="/commissions" element={<Commissions />} />
            <Route path="/imprint" element={<Imprint />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/ToS" element={<ToS />} />
            <Route path="/boop" element={<Boop />} />
            <Route path="/changelog" element={<ChangeLog />} />
            <Route path="/social_media" element={<SocialMedia />} />
            <Route element={<NotFound />} />
          </Routes>
        </Router>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
