import React from "react";
import "./pageStyles/links.scss";
import "./pageStyles/texts.scss";

class NotFound extends React.Component {
    render () { 
        return (
            <div>
                <h1>404, page not found</h1>
                <p className="spaced_text_container">The site you were searching for doesnt exist. Im searching myself, but I cant find it qwq<br/>
                If you think this is a bug please contact me at <a className="redLink" href="mailto:support@arduwu.com">my support mail</a><br/>
                If I sent you this link, feel free to tell me I messed up X3<br/>
                If you wanna go back to the home page, please follow the button below:<br/></p>
                <a className="FancyBackButton" href="/">Go Home</a>
            </div>
        );
    }
}

export default NotFound;