import React from "react";
import "./../../pageStyles/textHighlights.scss";

class CommissionRules extends React.Component {
    render () { 
        return (
            <div>
                <div className="Grid2Container">
                    <div className="inGridList">
                        <h3><span className="lightHide">Commission rules for</span> Artworks/Stickers</h3>
                        <p className="bulletListing">Extra characters will be priced additionally for half the price</p>
                        <p className="bulletListing">I can only do simple backgrounds (so far)</p>
                        <p className="bulletListing">Furries only, no scalies/avians/aquatics (yet)</p>
                        <p className="bulletListing">I draw both SFW and NSFW (NSFW only for 18+ years, and only males)</p>
                        <p className="bulletListing">I wont draw gore, kinks are negotiable</p>
                        <p className="bulletListing">Stickers can be commissioned in batches for one commission slot</p>
                    </div>
                    <div className="inGridList">
                        <h3><span className="lightHide">Commission rules for</span> VR Chat Avatars</h3>
                        <p className="bulletListing">Please tell me which model you'd like to have retextured</p>
                        <p className="bulletListing">You have to follow the Avatars ToS (licensing, pricing etc.)</p>
                        <p className="bulletListing">I only make NSFW Avatars for 18+ years old</p>
                        <p className="bulletListing">If nothing is said, I will do a PC Export only</p>
                        <p className="bulletListing">To add assets, the asset has to be provided to me by you</p>
                        <p className="bulletListing">I DO NOT do tutorials on how Im doing my avatars</p>
                    </div>
                </div>
                <div className="Grid1Container">
                    <div className="inGridList">
                        <h3>Please keep in mind</h3>
                        <p className="bulletListing">I only accept commissions via text</p>
                        <p className="bulletListing">Please provide me your ref sheet or some visual references of your sona</p>
                        <p className="bulletListing">Payment has to be done before I start with the commission</p>
                        <p className="bulletListing">I may increase the prices for complex sona designs or complex commissions</p>
                        <p className="bulletListing">Commissions can be cancelled due to reasons by both parties</p>
                        <p className="bulletListing">Refunds after a cancellation are possible depending on the progress of the commission</p>
                    </div>
                </div><br/>
                <div>
                    Also, please check out my <a className="redLink" href="/tos">Terms of Service</a> before commissioning me. <br/> Thank you ^w^
                </div>
            </div>
        );
     }
  }

export default CommissionRules;
