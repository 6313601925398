import React from "react";
import "./ComponentStyles/navbar.scss";
import $ from 'jquery';

class NavBar extends React.Component {
    constructor(props) {
        super(props);
        //this.onSelect = this.props.onSelect.bind(this)
        this.select_item = this.select_item.bind(this);
    }

    render () { 
        return (
            <div id="navbar-animmenu">
                <ul class="show-dropdown main-navbar">
                    <div class="hori-selector"><div class="circle left"></div><div class="circle right"></div></div>
                    <li onClick={this.select_item}>
                        <a href="/" >Home</a>
                    </li>
                    <li onClick={this.select_item}>
                        <a href="me" >Me</a>
                    </li>
                    <li onClick={this.select_item}>
                        <a href="sonas" >Sonas</a>
                    </li>
                    <li onClick={this.select_item}>
                        <a href="social_media" >Social Media</a>
                    </li>
                    <li onClick={this.select_item}>
                        <a href="commissions" >Commissions</a>
                    </li>
                    <li onClick={this.select_item}>
                        <a href="tos" >ToS</a>
                    </li>
                </ul>
            </div>
        );
    }

    // ---------horizontal-navbar-menu-----------
    componentDidMount() {
        // Get current path and find target link
        var path = window.location.pathname.split("/").pop();

        if ( path == '' ) {
            path = '/';
        }

        var target = $('#navbar-animmenu ul li a[href="'+path+'"]');

        if(target.length === 0) {
            $('.circle').hide();
            return;
        }

        // Add active class to target link
        target.parent().addClass('active');


		var tabsNewAnim = $('#navbar-animmenu');
		var activeItemNewAnim = tabsNewAnim.find('.active');
		var activeWidthNewAnimWidth = activeItemNewAnim.innerWidth();
		var itemPosNewAnimLeft = activeItemNewAnim.position();

		$(".hori-selector").css({
			"left":itemPosNewAnimLeft.left + "px",
            "top":itemPosNewAnimLeft.top + "px",
			"width": activeWidthNewAnimWidth + "px"
		});
    }

    select_item(e) {
        $('#navbar-animmenu ul li').removeClass("active");
        $(this).addClass('active');

        var activeWidthNewAnimWidth = $(this).innerWidth();
        var itemPosNewAnimLeft = $(this).position();

        $(".hori-selector").css({
            "left":itemPosNewAnimLeft.left + "px",
            "top":itemPosNewAnimLeft.top + "px",
            "width": activeWidthNewAnimWidth + "px"
        });
    }
}

export default NavBar;