import React from "react";

import ImpressumDE from "./Components/Impressum_de.jsx";
import ImpressumEN from "./Components/Impressum_en.jsx";

import "./pageStyles/switch.scss";
import "./pageStyles/imprint.scss";

class Imprint extends React.Component {
       
    constructor(props) {
        super(props);

        this.state = {
            "language": "en",
        }

        this.updateLanguage = this.updateLanguage.bind(this);
    }

    componentDidMount() {
        this.updateLanguage();
    }

    render () { 
        const impressum = () => {
            if(this.state.language === "de") {
                return <ImpressumDE />
            } else {
                return <ImpressumEN />
            }
        }

        return (
            <div className="Grid1Container">
                <div className="switchBox">
                    <p className="switchLabel">English <label className="switch">
                        <input type="checkbox" id="langCheckbox" onChange={() => {this.updateLanguage()}}/>
                        <span className="slider round"></span>
                    </label> Deutsch
                    </p>
                </div>
                <div className="inGridBox">{impressum()}</div>
            </div>
        );
    }

    updateLanguage() {
        this.setState({
            "language": document.getElementById("langCheckbox").checked ? "de" : "en",
        });
    }
}

export default Imprint;