import React from "react";
import "./../../pageStyles/commissions.scss"
import referencePicture from "./../../resources/images/Commission_explainer.png";

class CommissionInfo extends React.Component {
    render () { 
        return (
            <div className="GridContainer">
                <div className="inGridContainer">
                    <div className="leftDiv">
                        <h3>Im currently doing:</h3>
                        <h4 className="listing">
                            <a className="redLink" href="https://www.furaffinity.net/gallery/ardu/folder/1006196/Commissions">Artworks</a>
                        </h4>
                        <h4 className="listing">
                            <a className="redLink" href="https://t.me/joinchat/Auu20XFW8SJlZDMy">Stickers</a>
                        </h4>
                        <h4 className="listing">
                            <a className="redLink" href="https://twitter.com/ArduWolf/status/1663974482250219549">VR Chat Avatar retexturings</a>
                        </h4>
                        <h4 className="listing">
                            <a className="redLink" href="https://twitter.com/ArdenWolfsky/status/1664704079958888463">Fursuit Drawovers</a>
                        </h4><br/>
                        <h3 className="hasFootNote">Also, Im selling some <a className="redLink" href="https://www.furaffinity.net/gallery/ardu/folder/1101662/YCH/">YCHs</a></h3>
                        <p className="footNote listing">I still do YCHs even when Im not open for custom commission</p>
                        <p className="footNote listing">Some are NSFW, you're checking on your own risk</p>
                    </div>
                </div>
                <div className="inGridContainer">
                    <h3>Price sheet:</h3>
                    <div className="FlexContainer leftDiv">
                        <div className="fixedSizeDiv"><h4>Colored+Shaded</h4>
                            <p className="listing">Headshot - 14+€</p>
                            <p className="listing">Half-body - 22+€</p>
                            <p className="listing">Full-body - 30+€</p>
                        </div>
                        <div className="fixedSizeDiv"><h4>Flat colored</h4>
                            <p className="listing">Headshot - 10+€</p>
                            <p className="listing">Half-body - 17+€</p>
                            <p className="listing">Full-body - 24+€</p>
                        </div>
                        <div className="fixedSizeDiv"><h4>Sketch/lineart</h4>
                            <p className="listing">Headshot - 8+€</p>
                            <p className="listing">Half-body - 14+€</p>
                            <p className="listing">Full-body - 20+€</p>
                        </div>
                        <div className="fixedSizeDiv"><h3>Stickers</h3>
                            <p className="listing">8+€ per piece</p>
                            <p className="listing">Multiple characters per sticker: +3-5€</p>
                        </div>
                    </div>
                    <div className="fixedSizeDiv"><h3>VR Chat Avatars</h3>
                        <p className="listing">35+€ per retexturing</p>
                        <p className="listing">Avatar edits (like adding props) are seperate and for 5€ each</p>
                    </div>
                    <div className="fixedSizeDiv"><h3>Fursuit Drawovers</h3>
                        <p className="listing">Headshot - 8+€</p>
                        <p className="listing">Half-body - 14+€</p>
                        <p className="listing">Full-body - 20+€</p>
                        <br/>
                    </div>
                </div>
                <img className="commissionImage" src={referencePicture} alt="Reference picture"/>
            </div>
        );
    }
}

export default CommissionInfo;
