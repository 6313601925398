import React from "react";
import "./pageStyles/links.scss";
import "./pageStyles/container.scss";
import "./pageStyles/texts.scss";



class SocialMedia extends React.Component {
     
    render() {
        return (
            <div>
                <h1 className="title_text">You can find me on these social media sites</h1>
                <div className="flex_box">
                    <div className="square_container_blue">
                        <h3><a className="darkBlueLink" href="https://twitter.com/ArduWolf">Twitter</a></h3>
                    </div>
                    <div className="square_container_blue">
                        <h3><a className="darkBlueLink" href="https://t.me/Arduwu">Telegram</a></h3>
                    </div>
                    <div className="square_container_blue">
                        <h3><a className="darkBlueLink" href="https://t.me/joinchat/0owPoyvsCxc5OGEy">Sticker</a></h3>
                    </div>                    <div className="square_container_blue">
                        <h3><a className="darkBlueLink" href="https://bsky.app/profile/ardu.arduwu.com">Bluesky</a></h3>
                    </div>
                    <div className="square_container_red">
                        <h3><a className="redLink" href="https://www.furaffinity.net/user/ardu">Furaffinity</a></h3>
                    </div>
                    <div className="square_container_red">
                        <h3><a className="redLink" href="https://www.instagram.com/arduwolf/">Instagram</a></h3>
                    </div>
                </div>
            </div>
        );
    }
}



export default SocialMedia;