import React from "react";
import "./pageStyles/home.scss";
import "./pageStyles/images.scss";
import Sticker from "./resources/stickers/Sticker_Cute.png";
import "./pageStyles/links.scss";

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            not_cute_clicked: false,
        };

        this.onNotClick = this.onNotClick.bind(this);
        this.backToHome = this.backToHome.bind(this);
    }

    render () { 
        return (
            <div>
                {this.state.not_cute_clicked ? this.notCute() : this.homePage()}
            </div>
        );
    }

    homePage() {
        return (
            <div>
                <h1>Welcome to <a class="redLink" href="/">Arduwu.com</a> ^w^</h1>
                <h3>My small start of building a hub for my internet presence</h3>
                <h2>Here are some facts about me:</h2>
                <h2 class="left">
                    <ul>
                        <li>Im a wolf boi from germany</li>
                        <li>My sona is a winged wolf named Ardu</li>
                        <li>Im <span className="red_clickable" onClick={this.onNotClick}>NOT</span> cute</li>
                    </ul>
                </h2>
            </div>
        );
    }

    notCute() {
        return (
            <div>
                <img alt="Im not cute.png" className="dynamicImage" src={Sticker} /><br/><br/>
                <span className="FancyBackButton" onClick={this.backToHome}>Accept your cuteness</span>
            </div>
        )
    }

    onNotClick() {
        this.setState({
            not_cute_clicked: true,
        });
    }

    backToHome() {
        this.setState({
            not_cute_clicked: false,
        });
    }
}

export default HomePage;