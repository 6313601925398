import React from "react";
import "./pageStyles/sonas.scss";
import Ardu from "./resources/stickers/Ardu_Sticker31.png";
import Ino from "./resources/stickers/Ino_Sticker_01_1.png";
import Azur from "./resources/stickers/Azur_Sticker_20.png";
import "./pageStyles/images.scss";
import "./pageStyles/links.scss";

import sonasLore from "./resources/texts/sonaLore.json";

class Sonas extends React.Component {
    render () {
        return (
            <div className="SonaContainer">
                <Sona 
                    sonaName="Ardu"
                    sonaLore={sonasLore.ArduLore}
                    sonaImage={Ardu}
                    sonaLink="https://www.furaffinity.net/gallery/ardu/folder/824112/Ardu"
                    sonaLinkStyle="ArduLink"
                    isNSFW={false}
                />
                <Sona 
                    sonaName="Ino"
                    sonaLore={sonasLore.InoLore}
                    sonaImage={Ino}
                    sonaLink="https://www.furaffinity.net/gallery/ardu/folder/839450/Ino"
                    sonaLinkStyle="InoLink"
                    isNSFW={false}
                />
                <Sona 
                    sonaName="Azur"
                    sonaLore={sonasLore.AzurLore}
                    sonaImage={Azur}
                    sonaLink="https://www.furaffinity.net/gallery/ardu/folder/928811/Azur"
                    sonaLinkStyle="AzurLink"
                    isNSFW={true}
                />
            </div>
        );
    }
}

class Sona extends React.Component {
    render() {
        return (
            <div className="SonaRow">
                <p className="SonaDescriptor">
                    <a href={this.props.sonaLink}>
                        <img className="SonaImage" src={this.props.sonaImage} alt="Sticker"/>
                    </a><br/>
                </p>
                <p className="SonaLore">
                    <a href={this.props.sonaLink} className={this.props.sonaLinkStyle}>
                        {this.props.sonaName}
                    </a>{this.props.isNSFW ? <span className="smolDescriptor">(18+ Warning)</span> : null}<br/>
                    <p>{this.getSonaLore()}</p>
                </p>
            </div>
        )
    }

    getSonaLore() {
        let content = "";
        for(let row in this.props.sonaLore) {
            content += this.props.sonaLore[row] + "\n";
        }
        return content;
    }
}

export default Sonas;