import React from "react";
import "./pageStyles/divs.scss";
import "./pageStyles/links.scss";

class MePage extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            "title": "Hello, Awooo ^^",
            "age": 21,
            "clicked": false,
        }

        this.updateText = this.updateText.bind(this);
    }


    render () { 
        return (
            <div className="FlexCenterContent">
                <div className="maxHalfWidth">
                    <h1 onClick={this.updateText}>{this.state.title}</h1>
                    <p>So... about myself, I have to admit Im not the best in describing who I am.</p>
                    <p>To start though, I am {this.state.age} years old, gay, and currently living in germany. I dont really know what to state here more...</p>
                    <p>I joined the furry fandom on march 19th 2020 (the birthday of Ardu). I met lots of people here, and several of them became good friends ^w^</p>
                    <p>I finished my training as an application developer in an IT company, and I currently work there as a System Engineer. When Im not at work, I like to draw and program. If you didnt already notice, <a className="redLink" href="sonas">my sonas</a> names were kinda inspired by that X3</p>
                    <p>All I want to say now is: I hope you enjoy my content. If you want to get something for yourself, you can see my commission info <a className="redLink" href="/commissions">here</a></p>
                    <p>I dont really know what more to put here right now. Please have fun and feel free to click explore ^^</p>
                    <br/>
                    <h3>If you want to know some projects im Interested in:</h3>
                    <p>Please check out my friends <a className="redLink" href="/other_projects">Projects</a> page ^w^</p>
                </div>
            </div>
        );
    }

    updateText() {
        if(this.state.clicked) {
            
        } else {
            this.setState({
                "title": "Hewwooo, Awoooooo ^w^",
                "clicked": true
            });
        }
    }

}

export default MePage;