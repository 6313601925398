import React from "react";
import "./pageStyles/texts.scss";
import MoreContent from "./Components/Scroll_Hint";

class ToS extends React.Component {
       
    render () { 
        return (
            <div className="center_text">
                <div className="centered_text_container">
                    <h1>Terms of Service</h1>
                    <h3 className="text_header">Commission making</h3>
                    <p className="block_left">Before starting the the commission, I have to know the details about the commission. Please provide as many details as necessary. Visual references for the scene are very welcome too.</p>
                    <p className="block_left">For sonas, a visual reference is required, unless you're commissioning a ref-sheet. If no visual reference is provided, the slot will be dropped.</p>
                    <p className="block_left">Before commissioning, please make sure Im open for commissions. If Im closed for commissions I wont take on any new commissions. The only exception being YCHs, please check for slots on the YCH themself.</p>
                    <h3 className="text_header">18+ rules</h3>
                    <p className="block_left">I will not do any 18+ or related to 18+ content for minors. If you want to commission anything that is 18+, you have to tell me your age or make it otherwise visible.</p>
                    <p className="block_left">If I find out during the commission of an 18+ piece that you arent 18-years-old or older, I wont continue the commission and you wont be refunded.</p>
                    <h3 className="text_header">Payment</h3>
                    <p className="block_left">Payment has to be done before I start with the commission. If no payment has been done, I will send you a reminder. If no payment is done within 24 hours of that reminder, the slot will be dropped.</p>
                    <p className="block_left">If you wish for a payment plan, please tell me before the payment and we can arrange one.</p>
                    <h3 className="text_header">Refunds</h3>
                    <p className="block_left">Before starting the commission, if you cancel the commission, you can get a full refund. After starting the commission, depending on the progress, a partial refund is possible. If at least 50% of the commission is done, no more refund is possible.</p>
                    <p className="block_left">In case of a payment plan, if you cancel the commission, I will refund you what you already paid, as long as you are still abligable for it.</p>
                    <h3 className="text_header">publishing / crediting</h3>
                    <p className="block_left">I will publish every artwork I make unless requested otherwise by the client. This includes both publishing it on my social medias and in some cases using it as references for my services. For VR Chat Avatars, I will take pictures myself if none are provided.</p>
                    <p className="block_left">You cannot use my art for any commercial use. If you want to use it for anything commercial, please message me and we can talk about it.</p>
                    <p className="block_left">If you want to publish any of my art, please tag or link me on it, or make clear I made it.</p>
                    <h3 className="text_header">Commission delaying</h3>
                    <p className="block_left">If you have to delay the commission due to reasons like not being able to pay or not having a reference yet, please tell me, so I can move your slot back. Delays have to be made for a determined amount of time. Otherwise the slot will be dropped.</p>
                    <p className="block_left">If I have to interrupt the commission due to reasons like a sickness, the slots will stay, but be delayed until I can progress with them.</p>
                    <h3 className="text_header">Commission termination</h3>
                    <p className="block_left">If you want to or need to cancel the commission, please tell me so I can drop your slot. The refund depends on the progress of the commission so far.</p>
                    <p className="block_left">If Im unable to continue the commission process due to reasons like missing requirements, a very bad contact or the client breakin my ToS, the slot will be dropped.</p>
                    <h3 className="text_header">Commission edits</h3>
                    <p className="block_left">During the commission, I will work with the information I have and my current skill level.</p>
                    <p className="block_left">If you notice any mistakes on the current level of the commission, please tell me them immediately so I can fix them.</p>
                    <p className="block_left">If you want any edits on an already approved part of the commission, I will charge extra for that.</p>
                    <br/>
                    <h3 className="text_header">About the Terms or Service</h3>
                    <p className="block_left">The ToS might change in the future, depending on my own experience or in the case I missed something. If anything is not defined by the ToS or you have a question about them, please ask me about more details.</p>
                    <p className="block_left">I will add to the ToS or change something in case I find an error or an error is pointed out to me.</p>
                    <p className="block_left">The ToS active on the day you commissioned me apply. Make sure to keep a copy of my ToS to keep them constant. If not, the ToS which are currently on my website will apply.</p>
                    <p className="block_left">I still have the last word for my commissions.</p>
                    <br/>
                    <p className="block_left">By commissioning me, you automatically accept my Terms of Service. </p>
                </div>
                <MoreContent/>
            </div>
        );
    }
}



export default ToS;
