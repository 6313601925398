import React from "react";
import "./pageStyles/other_projects.scss";
import "./pageStyles/links.scss";

class OtherProjects extends React.Component {

    render () { 
        return (
            <div>
                <h1>Welcome to the projects Page ^w^</h1>
                <h2>Here I want to link to some other awesome projects or content</h2>
                <div id="project_list">
                    <div className="project_tile_parent">
                        <div className="project_tile">
                            <h3>Commissions by <a className="redLink" href="https://www.furaffinity.net/user/siniestasma4/">Siniestasma</a></h3>
                            <img alt="The commission page" src="https://pbs.twimg.com/media/FqZcY7JWYAIDY5N?format=jpg&name=large"></img>
                            <div className="link_list">
                                <span>Their <a className="blueLink" href="https://t.me/SiniestasmART44/267">Telegram</a></span>
                                <span>Their <a className="blueLink" href="https://twitter.com/Sini_Lombaxart">Twitter</a></span>
                            </div>
                        </div>
                    </div>
                    <div className="project_tile_parent">
                        <div className="project_tile">
                            <h3><a className="redLink" href="https://twitter.com/ArdenWolfsky">Ardens</a> drinks</h3>
                            <img alt="Arden holding a shaker" src="https://pbs.twimg.com/media/FqZ4h5ZWIAQEkVL?format=jpg&name=large"></img>
                            <div className="link_list">
                                <span>His <a className="blueLink" href="https://ardenwolfsky.carrd.co/">Social Media sites</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



export default OtherProjects;