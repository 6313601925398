import React from "react";
import "./pageStyles/links.scss";
import "./pageStyles/texts.scss";

class Boop extends React.Component {
    render () { 
        return (
            <div>
                <h1>I have been Booped</h1>
                <p>Welcome to my Lair {'>'}:3</p>
                <p>Its nice to get to know you, while you're already here please feel free to stay and look around ^w^</p>
                <p>Maybe have a look around on my <a className="redLink" href="/social_media">Socials</a> to get to know me more ;3</p>
                <p>If you want to get started, feel free to also go <a className="redLink" href="/">Home</a></p>
            </div>
        );
    }
}

export default Boop;