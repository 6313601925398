import React from "react";
import "./pageStyles/links.scss";
import "./pageStyles/texts.scss";

class Privacy extends React.Component {
       
    render () { 
        return (
            <div>
                <h1>Privacy Policy</h1>
                <p>Im <span className="redHighlight">NOT</span> collecting any data. If that updates, I will redo this page.</p>
                <p>The privacy policy of my host is <a className="redLink" href="https://www.cloudflare.com/privacypolicy/">this one</a>.</p>
                <p>Just go around and have fun, make sure to protect nature and to be good to other people ^w^</p><br/>
                <a href="/" className="FancyBackButton">Go Home</a>
            </div>
        );
    }
}



export default Privacy;
