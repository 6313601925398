import React from "react";
import "./pageStyles/links.scss";
import "./pageStyles/texts.scss";
import "./pageStyles/divs.scss";
import CommissionInfo from  "./Components/Commissions/commissionInfo";
import CommissionRules from "./Components/Commissions/commissionRules";
import party from "party-js";
import MoreContent from "./Components/Scroll_Hint";

class Commissions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "rules": false,
        };
    }

    componentDidMount() {
        this.emitConfetti();
    }

    render () { 
        return (
            <div>
                <h2 className="fitContent" id="confettiSpawner" onClick={this.emitConfetti}>Welcome to my commission page! ^w^</h2>
                <p>I do <strong>artwork </strong>commissions, <strong>telegram stickers</strong> and <strong>VR Chat Avatar retextures</strong>.</p>
                <h3>If you're interested, please contact me on my <a className="blueLink" href="https://twitter.com/ArduWolf">Twitter</a> <a className="redLink" href="https://twitter.com/ArduWolf">(@ArduWolf)</a> or <a className="blueLink" href="https://t.me/Arduwu">Telegram</a> <a className="redLink" href="https://t.me/Arduwu">(@Arduwu)</a></h3>
                {this.renderMainContent()}
                {this.renderBottomPart()}
                <MoreContent/>
            </div>
        );
    }

    renderMainContent() {
        if(this.state.rules) {
            return (<CommissionRules/>);
        } else {
            return (<CommissionInfo/>);
        }
    }

    renderBottomPart() {
        if(this.state.rules) {
            return (<p className="redLink" onClick={() => this.setState({"rules": false})}>Back to the info page</p>)
        } else {
            return (<p>If you have any questions or something else, please message me so we can talk about it<br/>Also, please look into my <span className="redLink" onClick={() => this.setState({"rules": true})}>commission rules</span> for more information, and check if Im open for commissions ;3</p>);
        }
    }

    emitConfetti() {
        let emitter = document.getElementById("confettiSpawner");
        if(Date.now() % 2 === 0) {
            party.sparkles(emitter, {count:80});
        }
        else {
            party.confetti(emitter, {count:200});
        }
    }
}

export default Commissions;