import React from "react";
import "./ComponentStyles/Scroll_Hint.scss";
import { unmountComponentAtNode } from "react-dom";

class MoreContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hidden: false,
        }
        this.hide = this.hide.bind(this);
        window.addEventListener('scroll', this.hide, true);
    }

    render () { 
        return (
            <div className="MoreContent">
                <DownArrow hidden={this.state.hidden}/>
            </div>
        );
    }

    hide() {
        if(!this.state.hidden) {
            this.setState({
                hidden: true,
            });
        }
    }
}

function DownArrow({hidden}) {
    if(hidden) {
        return(<div></div>);
    } else {
        return (<div className="OuterCircle">
            <div className="DownArrow">
                <h1>↓</h1>
            </div>
        </div>);
    }
}

export default MoreContent;