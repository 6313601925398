import React from "react";
import "./pageStyles/texts.scss";

class ChangeLog extends React.Component {
    render () { 
        return (
            <div className="centered_text_container">
                <p><h1>ChangeLog</h1>
                All changes I made on this website in chronological order</p>
                <p className="block_left"><h3>Initial release</h3>
                    <ul>
                        <li>Created the Website</li>
                        <li>Added header and footer</li>
                        <li>Created the hub Page</li>
                        <li>Created the About pages</li>
                        <li>Created the Imprint and Privacy Policy</li>
                        <li>Created the Commission page</li>
                        <li>Created the Terms of Service page</li>
                    </ul>
                </p>
                <p className="block_left"><h3>Version 1.1</h3>
                    <ul>
                        <li>Added the Changelog (for anyone who's interested)</li>
                        <li>Fixed a bug on the Imprint page</li>
                    </ul>
                </p>
                <p className="block_left"><h3>Version 1.2</h3>
                    <ul>
                        <li>Added a statement to the ToS</li>
                        <li>Fixed some typos</li>
                        <li>Added a 404 page</li>
                    </ul>
                </p>
                <p className="block_left"><h3>Version 1.3</h3>
                    <ul>
                        <li>Added a statement to the Commission Rules</li>
                        <li>Redid parts of the Commission Site</li>
                        <li>Added an explanation image to the Commission site</li>
                        <li>Fixed a lot of SCSS</li>
                    </ul>
                </p>
                <p className="block_left"><h3>Version 1.4</h3>
                    <ul>
                        <li>Fixed bugs cause by previous versions</li>
                        <li>Updated my ToS (again)</li>
                        <li>Added an entry to my Commission rules</li>
                    </ul>
                </p>
                <p className="block_left"><h4>Version 1.4.1</h4>
                    <ul>
                        <li>Updated my commissions page a bit</li>
                    </ul>
                </p>
                <p className="block_left"><h4>Version 1.4.2</h4>
                    <ul>
                        <li>Added a new link to the navbar</li>
                        <li>Updated a part of the me page</li>
                        <li>Clarified a part of my commission and Terms of Service page</li>
                    </ul>
                </p>
                <p className="block_left"><h4>Version 1.4.3</h4>
                    <ul>
                        <li>Changed Favicon</li>
                        <li>Updated the ToS and Commission Info</li>
                    </ul>
                </p>
                <p className="block_left"><h3>Version 1.5</h3>
                    <ul>
                        <li>Added a new navbar</li>
                        <li>Changed a line in the ToS</li>
                        <li>Added a new Social Media site</li>
                    </ul>
                </p>
                <p className="block_left"><h4>Version 1.5.1</h4>
                    <ul>
                        <li>Added awesome other projects page</li>
                        <li>Fixed a bug in the header</li>
                    </ul>
                </p>
                <p className="block_left"><h4>Version 1.5.2</h4>
                    <ul>
                        <li>Rewrote the project with newer versions of React</li>
                        <li>Updated Links to include bluesky</li>
                        <li>Added Boop Page</li>
                    </ul>
                </p>
            </div>
        );
    }
}

export default ChangeLog;